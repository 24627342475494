import { getField, updateField } from 'vuex-map-fields'
import api from '@/services/api'
import { limit, where } from 'firebase/firestore'

export const state = () => ({})

export const getters = {
  getField
}

export const actions = {
  async get(_, id) {
    const { data } = await api.get(`/user-actions/${id}`)
    return data
  },
  async list(_, { params = {} }) {
    console.log('params', params)
    const { data } = await api.get('/user-actions', {
      params: {
        ...params,
        limit: params.limit || 10,
        where: JSON.stringify(params.where || {})
      }
    })
    return data
  },
  async create(_, payload) {
    const { data } = await api.post('/user-actions', payload)
    return data
  },
  async update(_, payload) {
    const { data } = await api.put(`/user-actions/${payload.id}`, payload)
    return data
  },
  async delete(_, id) {
    await api.delete(`/user-actions/${id}`)
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
